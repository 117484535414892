import { Route, Routes } from "react-router-dom";
import { QuestionnairePage } from "./questionnaire";
import { ThanksPage } from "./thanks";

export const Pages = () => (
  <Routes>
    <Route path="/thanks" element={<ThanksPage />} />
    <Route path="/" element={<QuestionnairePage />} />
  </Routes>
);
