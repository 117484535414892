import styled from "styled-components";

// noinspection CssUnknownTarget
export const Letter = styled.div`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: auto;
  padding: 30px;
  background-image: url("paper.jpeg");
  font-size: min(3.2vw, 20px);
  font-family: "Noto Serif JP", serif;
`;
