import { Dimmer, Form, FormField, Loader } from "semantic-ui-react";
import * as zod from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useGoogleFormClient } from "../../../infra/google-form/use-google-form-client";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { Letter } from "../common/letter";

const formSchema = zod.object({
  name: zod.string().min(1, { message: "必須項目です" }),
  favoriteSong1: zod.string().nullable(),
  favoriteSong2: zod.string().nullable(),
  favoriteSong3: zod.string().nullable(),
  favoriteSong4: zod.string().nullable(),
  favoriteSong5: zod.string().nullable(),
  memorableSong: zod.string().nullable(),
  message: zod.string().nullable(),
  attendance: zod.string({ required_error: "必須項目です" }),
});

export type TFormField = zod.infer<typeof formSchema>;

const gFormURL =
  "https://docs.google.com/forms/u/0/d/e/1FAIpQLSePa2eL4JR26PQ4CgQ-kcc6anMyfqD3XWZlMDjpFemcXVUl6w/formResponse";

const gFormFieldNames = {
  name: "entry.1673348293",
  memorableSong: "entry.2095518357",
  favoriteSong1: "entry.1341218589",
  favoriteSong2: "entry.1789707440",
  favoriteSong3: "entry.82440392",
  favoriteSong4: "entry.1553680169",
  favoriteSong5: "entry.2061165416",
  attendance: "entry.1846344290",
  message: "entry.832787132",
};

const isNullOrEmpty = (text: string | null) =>
  text == null || text.length === 0;

interface QuestionnaireFormProps {
  onComplete: () => void;
}
export const QuestionnaireForm = (props: QuestionnaireFormProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<TFormField>({
    // zodを使用する設定
    resolver: zodResolver(formSchema),
  });

  const [isLoading, setIsLoading] = useState(false);

  const { send } = useGoogleFormClient(gFormURL, gFormFieldNames);
  const watchFavorites = watch([
    "favoriteSong1",
    "favoriteSong2",
    "favoriteSong3",
    "favoriteSong4",
    "favoriteSong5",
  ]);

  const onSubmit: SubmitHandler<TFormField> = useCallback(
    async (data) => {
      try {
        setIsLoading(true);
        await send(data);
        setIsLoading(false);
        props.onComplete();
      } catch (e) {
        setIsLoading(false);
        alert("送信に失敗しました。ネットワーク状況を確認してください");
      }
    },
    [send, props],
  );
  return (
    <FormWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField>
          <label style={{ color: "#6f3620" }}>
            お名前 <FormErrorMsg>{errors.name?.message}</FormErrorMsg>
          </label>
          <input placeholder="" {...register("name")} />
        </FormField>
        <FormField>
          <label style={{ color: "#6f3620" }}>
            出欠 <FormErrorMsg>{errors.attendance?.message}</FormErrorMsg>
          </label>
          <Form.Select
            name="attendance"
            options={[
              { key: "true", text: "出席", value: "出席" },
              { key: "false", text: "欠席", value: "欠席" },
            ]}
            onChange={(_, { name, value }) => setValue(name, value)}
            error={!!errors.attendance}
          />
        </FormField>
        <FormField>
          <label style={{ color: "#6f3620" }}>あなたの十八番は？</label>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <input placeholder="" {...register("favoriteSong1")} />
            <input
              placeholder=""
              {...register("favoriteSong2")}
              style={{
                display: isNullOrEmpty(watchFavorites[0])
                  ? "none"
                  : "inline-block",
              }}
            />
            <input
              placeholder=""
              {...register("favoriteSong3")}
              style={{
                display: isNullOrEmpty(watchFavorites[1])
                  ? "none"
                  : "inline-block",
              }}
            />
            <input
              placeholder=""
              {...register("favoriteSong4")}
              style={{
                display: isNullOrEmpty(watchFavorites[2])
                  ? "none"
                  : "inline-block",
              }}
            />
            <input
              placeholder=""
              {...register("favoriteSong5")}
              style={{
                display: isNullOrEmpty(watchFavorites[3])
                  ? "none"
                  : "inline-block",
              }}
            />
          </div>
          <p className="error-message">{errors.favoriteSong1?.message}</p>
        </FormField>
        <FormField>
          <label style={{ color: "#6f3620" }}>軽音での思い出の一曲</label>
          <input placeholder="" {...register("memorableSong")} />
          <p className="error-message">{errors.memorableSong?.message}</p>
        </FormField>
        <FormField>
          <label style={{ color: "#6f3620" }}>何かメッセージがあれば！</label>
          <input placeholder="" {...register("message")} />
          <p className="error-message">{errors.message?.message}</p>
        </FormField>
        <SendButton type="submit">Send</SendButton>
      </Form>
      <Dimmer active={isLoading} inverted page>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    </FormWrapper>
  );
};

const FormWrapper = styled(Letter)`
  margin-top: -80px;
  padding-top: 100px;
  width: 95%;
  max-width: 900px;
  z-index: 0;
  border-radius: 5px;
  color: #6f3620;
`;

const FormErrorMsg = styled.span`
  font-size: 10px;
  color: darkred;
`;

const SendButton = styled.button`
  background-color: #9b535e;
  color: white;
  border: 0;
  width: 100px;
  height: 30px;
  margin: auto;
  display: block;
  border-radius: 30px;
  font-size: 1.5rem;
  font-family: "Allura", cursive;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;
