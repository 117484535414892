export const useGoogleFormClient = (
  formURL: string,
  formFieldNames: { [key: string]: string },
) => {
  const urlEncode = (data: { [key: string]: any }) => {
    const result: { [key: string]: any } = {};
    Object.keys(data).map((key) => (result[formFieldNames[key]] = data[key]));
    return new URLSearchParams(result).toString();
  };

  const send = async (data: { [key: string]: any }) => {
    await fetch(formURL, {
      method: "POST",
      // urlEncodeされた値をbodyに入れる
      body: urlEncode(data),
      // 通常ではcorsに弾かれる (返却値のstatusは常に0 後述...)
      mode: "no-cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  };

  return { send };
};
