import styled from "styled-components";
import { Message } from "./common/message";
import { Flower } from "./common/Flower";
import { MessageText } from "./common/message-text";
import { useNavigate } from "react-router-dom";
import { Letter } from "./common/letter";
import { QuestionnaireForm } from "./questionnaire-form";
import { Header } from "./header";

export const QuestionnairePage = () => {
  const navigate = useNavigate();

  return (
    <Body>
      <Header />
      <DescriptionWrapper>
        <DescriptionMessageWrapper>
          <Message />
        </DescriptionMessageWrapper>
        <DescriptionFlowerWrapper>
          <Flower />
        </DescriptionFlowerWrapper>
        <Description>
          {MessageText.split(`\n`).map((x) => (
            <>
              {x}
              <br />
            </>
          ))}
        </Description>
      </DescriptionWrapper>
      <QuestionnaireForm onComplete={() => navigate("/thanks")} />
      <Footer />
    </Body>
  );
};

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  background-color: #fffefb;
`;

const DescriptionWrapper = styled(Letter)`
  width: 80%;
  max-width: 800px;
  z-index: 1;
  text-align: center;
`;

const DescriptionMessageWrapper = styled.div`
  width: 125px;
  margin: 10px auto 0;
`;

const DescriptionFlowerWrapper = styled.div`
  margin: 20px auto 30px;
`;

const Description = styled.div`
  width: 100%;
  margin: auto;
  line-height: 1.6;
  color: #6f3620;
`;

const Footer = styled.div`
  height: 50px;
`;
