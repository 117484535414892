import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css"; // デフォルトのテーマを読み込んでいます（コアスタイルのみ読み込む設定も可能）

interface FaceSlideShowProps {
  name: string;
  urls: string[];
}
export const FaceSlideShow = (props: FaceSlideShowProps) => {
  return (
    <>
      <Splide
        aria-label={`slide-show-${props.name}`}
        options={{
          autoplay: true,
          interval: 5000,
          type: "fade",
          arrows: false,
          pagination: false,
          rewind: true,
          speed: 1000,
        }}
      >
        {props.urls.map((x, i) => (
          <SplideSlide>
            <img className="slide-img" src={x} alt={`${props.name}-${i}`} />
          </SplideSlide>
        ))}
      </Splide>

      {/* 画像の高さを揃えて表示させるために以下スタイルを適用 */}
      <style>{`
        .slide-img {
          display: block;
          width: 100%;
        }
      `}</style>
    </>
  );
};
