import styled from "styled-components";
import { FaceSlideShow } from "./face-slide-show";

const upperMembers = ["sota", "rikiya", "kitaba"];
const lowerMembers = ["remi", "kimura", "shiojo"];

const upperDegs = [7, -15, 7];
const lowerDegs = [-9, 12, -18];

export const ThanksPage = () => (
  <Body>
    <Pictures>
      {upperMembers.map((x, i) => (
        <PictureWrapper deg={upperDegs[i]}>
          <FaceSlideShow
            name={x}
            urls={[`member/${x}0.png`, `member/${x}1.png`, `member/${x}2.png`]}
          />
        </PictureWrapper>
      ))}
    </Pictures>
    <Message>Thanks!</Message>
    <Pictures>
      {lowerMembers.map((x, i) => (
        <PictureWrapper deg={lowerDegs[i]}>
          <FaceSlideShow
            name={x}
            urls={[`member/${x}0.png`, `member/${x}1.png`, `member/${x}2.png`]}
          />
        </PictureWrapper>
      ))}
    </Pictures>
  </Body>
);

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 80px;
  width: 100%;
  overflow: hidden;
  background-color: #fffefb;
`;

const Message = styled.div`
  font-family: "Allura", cursive;
  //font-size: 94px;
  font-size: clamp(94px, calc(100vw / 10), 216px);
  color: #9b535e;
  line-height: 120px;
`;

const Pictures = styled.div`
  display: flex;
  max-width: 800px;
`;

const PictureWrapper = styled.div<{ deg: number }>`
  margin: 0 -10px;
  width: min(35vw, 280px);
  height: min(35vw, 280px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
  transform: rotate(${(props) => props.deg}deg);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border: solid 2px whitesmoke;
`;

const Picture = styled.img`
  width: 100%;
`;
