import styled from "styled-components";

export const Header = () => (
  <InvitationImageWrapper>
    <InvitationImage src={"invitation.png"} />
  </InvitationImageWrapper>
);

const InvitationImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  //height: 550px;
`;

const InvitationImage = styled.img`
  width: 100%;
  max-width: 600px;
`;
