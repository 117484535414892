export const MessageText = `
寒暖差激しい日々が続いておりますが
皆様お元気でお過ごしでしょうか

突然のご報告ではございますが
颯太と玲海 力也と香菜子 知行と詩織は
めでたく結婚することになりました

つきまして 日頃お世話になっている皆様へ
感謝を込めて ささやかではございますが
生バンドカラオケパーティを
企画したいと考えております

選曲にあたって 皆様に事前アンケートへの
ご協力をお願いいたします

あくまで調査段階ですので お気軽に
ご回答いただけますと 幸いでございます
`;
